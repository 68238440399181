import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { pedidoCardapioState, callCardapioState } from '../../store/dataRecoil'
import { useRecoilState } from 'recoil'
import { useNavigate, useLocation } from 'react-router-dom'
import { Avatar, Flex, App, Badge, Modal, Button, Alert, Drawer, Image, Divider } from 'antd'
import { socket } from '../../service/socket'
import './../appwaiter/waiterOutlet.css'
import carrinhoPNG from '../../img/carrinho-de-compras.png'
import garcomPNG from '../../img/campainha-de-mesa.png'
import './headerScroll.css'
import { api } from '../../service/api.service'
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons'
import * as moment from 'moment'
import devoltaPNG from '../../img/de-volta.png'
import { PedidoCardapio } from './pedidoCtrl'

//const imageUrl = 'https://menuengfood.com.br/images/background/bcg-1.png' //'http://www.crqx.org.br/wp-content/uploads/2023/01/cropped-logo-wordpress.png'

export const CardapioOutlet = (props) => {


    const navigate = useNavigate()
    const location = useLocation()
    const [openModalGarcom, setOpenModalGarcom] = useState(false)
    const { notification, modal } = App.useApp()
    const [pedido, setPedido] = useRecoilState(pedidoCardapioState)
    const [call, setCall] = useRecoilState(callCardapioState)
    const [scroll, setScroll] = useState(0)
    const [loading, setLoading] = useState(false)

    const [openPedido, setOpenPedido] = useState(false)
    const [processando, setProcessando] = useState(false)

    useEffect(() => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = 'https://menuengfood.com.br/logos/logomanager.png';
        document.title = pedido?.data?.fantasia ? pedido?.data?.fantasia : "Cardapio Online"
        //  console.log(pedido)
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScroll(window.scrollY)
        });
        return window.removeEventListener('scroll', () => { });

    }, [pedido])

    useEffect(() => {
        checkCalls()
        const intervalId = setInterval(() => {
            //  checkCalls()
        }, 25000)
        return () => clearInterval(intervalId);
    }, [])

    useEffect(() => {
        const intervalClear = setTimeout(() => {
            navigate('/order', {
                replace: true
            })
        }, 10800000)
        return () => clearTimeout(intervalClear);
    }, [])


    useEffect(() => {
        if (pedido?.itens?.length === 0)
            setProcessando(true)
        else
            setProcessando(false)
    }, [pedido])


    useEffect(() => {
        function onConnect() {
            socket.emit('sala', pedido?.sala?.concat('-waiter'))
        }

        function onDisconnect() {
        }

        function onUpdateCallGarcom(_call) {
            try {
                console.log(_call)
                if (_call.mesaid === pedido?.data?.mesaID) {

                    setCall(_call)
                }
            } catch (error) {

            }
            // console.log('updateCall', _call)
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('updateCallGarcom', onUpdateCallGarcom);

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('updateCallGarcom', onUpdateCallGarcom);
        };
    }, []);

    async function checkCalls() {
        try {
            //console.log(pedido)
            let data = {
                sala: pedido?.sala,
                mesaid: pedido?.data?.mesaID
            }
            var result = await api.post('/api/v1.0/manager/cardapio/getcallgarcom', data, {
                headers: {
                    'x-access-token': pedido?.data.token,
                    "sala": pedido?.sala
                }
            })
            if (result.data.status == 200) {
                if (result.data.data) {
                    var t = moment(result.data.data.createAt).format('YYYY-MM-DD HH:mm:ss')
                    result.data.data.createAt = t
                    setCall(result.data.data)
                }
            }
            // console.log(call)
        } catch (error) {
            console.log(error.message)
        }
    }

    async function callGarcom(ok) {
        try {
            setLoading(true)
            let mesa = {
                sala: pedido?.sala,
                mesaID: pedido?.data?.mesaID,
                clienteok: 0
            }

            var result = await api.post('/api/v1.0/manager/cardapio/callGarcom', mesa, {
                headers: {
                    'x-access-token': pedido?.data.token,
                    "sala": pedido?.sala,

                }
            })

            if (result.data.status == 200) {
                setCall(result.data.data)
                if (!ok)
                    notification.info({
                        style: { zIndex: 999999 },
                        placement: 'bottom',
                        duration: 4,
                        message: "Atenção!",
                        description: "Solicitação enviada, aguarde o atendente chegar."
                    })
            } else {
                notification.error({
                    style: { zIndex: 999999 },
                    placement: 'bottom',
                    duration: 4,
                    message: "Atenção!",
                    description: "Erro ao efetuar solicitação."
                })
            }


        } catch (error) {
            notification.error({
                style: { zIndex: 999999 },
                placement: 'bottom',
                duration: 4,
                message: "Atenção!",
                description: error.message
            })
        }
        setLoading(false)
    }



    async function enviarPedido() {
        setProcessando(true)
        var pedidoTemp = JSON.parse(JSON.stringify(pedido))
        try {
            if (!pedido?.nome) {
                // console.log('nome')
                setProcessando(false)
                notification.error({
                    zIndex: 99999999,
                    style: { zIndex: 9999999 },
                    description: "Informe seu nome para enviar o pedido!",
                    duration: 4,
                    placement: 'bottomRight',
                })
                return
            }



            pedidoTemp.itens.map((item, index) => {
                item.descricao = item.nome
                item.obs = item.Obs
                item.complementos.map((subitem, subindex) => {
                    subitem.descricao = subitem.NomeReduzido
                    return subitem
                })
                return item
            })

            if (pedidoTemp.itens.length > 0) {
                pedidoTemp.itens.map(item => {
                    item.complementos.map(comp => {
                        comp.venda = comp.Venda
                        comp.produtoID = comp.ProdutoID
                        return comp
                    })
                    return item;
                })

                pedidoTemp.itens[0].mesaID_comID = pedido?.data?.mesaID
                pedidoTemp.itens[0].vendaID = 0
                pedidoTemp.itens[0].garcomID = 0
                pedidoTemp.vendaID = 0
                pedidoTemp.garcomID = 0
                pedidoTemp.mesaid = pedidoTemp.mesaID

                const responde = await api.post('api/v1.0/manager/cardapio/new-order-qrcode',
                    pedidoTemp, {
                    headers: {
                        'x-access-token': pedido?.data.token,
                        "sala": pedido?.sala,
                    }
                })

                if (responde.data.status === 200) {
                    modal.success(
                        {
                            content: 'Pedido enviado com sucesso!',
                            centered: true,
                            closable: false,
                            zIndex: 99999999

                        })
                    setPedido({ ...pedido, itens: [] })
                    setOpenPedido(false)
                } else {

                    modal.error({
                        content: "O pedido não foi enviado!",
                        centered: true,
                        closable: false,
                        zIndex: 99999999
                    })
                }
                setProcessando(false)
            } else {
                modal.warning({
                    content: "Adicione itens para efetuar o pedido!",
                    centered: true,
                    closable: false,
                    zIndex: 99999999
                })
                setProcessando(false)
            }
        } catch (error) {
            // notification.error({ description: error.message, duration: 3 })
            modal.error({
                content: "Erro ao enviar pedido! ".concat(error.message),
                centered: true,
                closable: false,
                zIndex: 99999999
            })
            setProcessando(false)
            console.log('error.message', error.message)
        }
    }




    function renderCallModal() {
        if (call) {
            const minutos = moment().diff(moment(call.createAt), 'minute')
            if (minutos < 5) {
                if (call.garcom === '') {
                    return <Flex vertical>
                        <Flex>{`Já existe uma solicitação feita há ${minutos} minutos, por favor aguarde atentende.`}</Flex>
                        <Flex align='center' justify='center' style={{ marginTop: 20 }}>
                            <Button onClick={() => setOpenModalGarcom(false)}
                                style={{ margin: 5 }}
                                type='primary'>
                                Ok, entendi
                            </Button>
                        </Flex>
                    </Flex>
                } else {
                    return <Flex vertical>
                        <Flex>{`Deseja que o atendente venha até você?`}</Flex>
                        <Flex align='center' justify='center' style={{ marginTop: 20 }}>
                            <Button onClick={() => setOpenModalGarcom(false)}
                                style={{ margin: 5 }}>
                                Não
                            </Button>
                            <Button type='primary'
                                style={{ margin: 5 }}
                                onClick={() => {
                                    callGarcom()
                                    setOpenModalGarcom(false)
                                }}>
                                Sim
                            </Button>
                        </Flex>
                    </Flex>
                }
            } else {
                return <Flex vertical>
                    <Flex>{`Deseja que o atendente venha até você?`}</Flex>
                    <Flex align='center' justify='center' style={{ marginTop: 20 }}>
                        <Button onClick={() => setOpenModalGarcom(false)}
                            style={{ margin: 5 }}>
                            Não
                        </Button>
                        <Button type='primary' onClick={() => {
                            callGarcom()
                            setOpenModalGarcom(false)
                        }}
                            style={{ margin: 5 }}>
                            Sim
                        </Button>
                    </Flex>
                </Flex>
            }
        } else {
            return <Flex vertical>
                <Flex>{`Deseja que o atendente venha até você?`}</Flex>
                <Flex align='center' justify='center' style={{ marginTop: 20 }}>
                    <Button onClick={() => setOpenModalGarcom(false)}>
                        Não
                    </Button>
                    <Button onClick={() => {
                        callGarcom()
                        setOpenModalGarcom(false)
                    }}>
                        Sim
                    </Button>
                </Flex>
            </Flex>
        }


    }



    return <div>
        <section className="section-1" style={{
            background: `url(${pedido?.data?.urlbg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
        }}>
            <header style={{
                background: scroll > 100 ? `url(${pedido?.data?.urlbg})` : 'transparent'
            }}>



                <Avatar
                    src={require('../../img/logo.png')} size={'large'}
                    style={{ marginLeft: 8, height: 32, width: 32, opacity: 1 }} />
                <nav style={{ opacity: 1 }}>
                    <div style={{
                        marginLeft: 0, textTransform: 'uppercase', fontWeight: 'bold', color: 'white',
                    }}>
                        <span> {pedido?.data?.fantasia} </span>
                        <span style={{ fontSize: '0.65em' }}>{'(mesa '.concat(pedido?.data?.mesaID, ')')}</span>
                    </div>
                </nav>


                <div style={{
                    position: 'absolute',
                    top: 12,
                    right: 12
                }}>
                    <Flex style={{ width: 70, justifyContent: 'space-between' }}>
                        <Badge count={null /*call.length === 0 ? null : call.length*/} size='small'>
                            <Avatar src={garcomPNG}
                                shape='square'
                                style={{ backgroundColor: 'transparent' }}
                                alt='cart'
                                onClick={() => {
                                    setOpenModalGarcom(true)
                                }}
                            />
                        </Badge>
                        <Badge count={pedido?.itens?.length === 0 ? null : pedido?.itens?.length} size='small'
                        >
                            <Avatar src={carrinhoPNG} alt='cart'
                                shape='square'
                                
                                style={{ backgroundColor: 'transparent' }}
                                onClick={() => {
                                    if (pedido?.itens?.length > 0)
                                        setOpenPedido(true)
                                    else
                                        notification.info({
                                            description: "Nenhum produto adicionado!",
                                            style: { zIndex: 999999 },
                                            placement: 'bottom',
                                            duration: 4,
                                            message: "Atenção!",
                                        })
                                }} />
                        </Badge>
                    </Flex>
                </div>
            </header>
        </section>
        <section className="section-2">
            <Outlet />
        </section>


        <Modal
            zIndex={10003}
            destroyOnClose={true}
            title="Chamar garçom!"
            open={openModalGarcom}
            onOk={() => {
                if (call) {
                    const minutos = moment().diff(moment(call.createAt), 'minute')
                    if (call.garcom === '') {
                        if (minutos < 5) {
                            notification.info({
                                style: { zIndex: 999999 },
                                placement: 'bottom',
                                duration: 4,
                                message: "Atenção!",
                                description: `Já existe uma solicitação feita há ${minutos} minutos, aguarde atentende.`
                            })
                            setOpenModalGarcom(false)
                        } else {
                            setOpenModalGarcom(false)
                            callGarcom()
                        }
                    } else {
                        setOpenModalGarcom(false)
                        callGarcom()
                    }
                } else {
                    setOpenModalGarcom(false)
                    callGarcom()
                }
                setOpenModalGarcom(false)

            }}
            onCancel={() => setOpenModalGarcom(false)}
            footer={null}
            okText="Sim"
            cancelText="Não" >
            {
                renderCallModal()
            }
        </Modal>


        <Drawer
            zIndex={999999}
            title={null}
            placement={"right"}
            width={'100%'}
            closable={false}
            onClose={() => {
                setOpenPedido(false)
            }}
            className='section-3'
            open={openPedido}
            footer={
                <Flex gap={5}>
                    <Button block icon={<DeleteOutlined />} type='primary' danger onClick={() => {
                        setPedido({ ...pedido, itens: [] })
                        setOpenPedido(false)
                    }}>
                        Cancelar
                    </Button>



                    <Button
                        block
                        icon={<CheckOutlined />}
                        type='primary'
                        disabled={pedido?.length === 0 || !pedido || !pedido?.nome}
                        loading={processando}
                        onClick={() => enviarPedido()}>
                        Confirma
                    </Button>
                </Flex>
            }

        >
            <Flex vertical style={{ width: '100%' }}>

                <Flex align='center'>
                    <Image src={devoltaPNG}
                        height={22} width={22}
                        style={{ objectFit: 'contain' }} preview={false}
                        onClick={() => setOpenPedido(false)}
                    />
                    <Avatar
                        src={require('../../img/logo.png')} size={'large'}
                        style={{ marginLeft: 8, height: 32, width: 32, opacity: 1 }} />
                    <nav style={{ opacity: 1 }}>
                        <div style={{
                            marginLeft: 0, textTransform: 'uppercase', fontWeight: 'bold', color: pedido?.data?.cor,

                        }}>
                            <span> {pedido?.data?.fantasia} </span>
                        </div>
                    </nav>
                </Flex>

                <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                <PedidoCardapio />

            </Flex>
        </Drawer>



    </div>



}

