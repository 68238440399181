import React, { useEffect, useState } from 'react'
import { Button, Flex, App, Breadcrumb } from 'antd'
import { socket } from '../../../service/socket'
import { useNavigate } from 'react-router-dom';
import { waiterUserState, mesaState, pedidoWaiterState } from '../../../store/dataRecoil'
import { useRecoilState } from 'recoil'
import { api } from '../../../service/api.service'
import { isMobile } from 'react-device-detect';
import { FlexboxGrid } from 'rsuite';

export const WaiterComandas = (props) => {

    const { notification } = App.useApp()
    const [waiterUser, setWaiterUser] = useRecoilState(waiterUserState)
    const [pedido, setPedido] = useRecoilState(pedidoWaiterState)
    const [mesa, setMesa] = useRecoilState(mesaState)
    const [comandas, setComandas] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        setPedido({
            createdAt: new Date().getTime(),
            local: '',
            itens: [],
            type: 'COMANDA'
        })
        setMesa(undefined)
        getComandas()

        function onComandas(value) {
            if (Array.isArray(value))
                setComandas(value)
            console.log("onComandas: ", value)
        }

        socket.on('updates-comandas', onComandas);

        return () => {
            socket.off('updates-comandas', onComandas);

        };

    }, [])


    async function getComandas() {
        try {

            const result = await api.get('api/v1.0/manager/comandas', {
                params: {
                    sala: waiterUser?.database
                },
                headers: {
                    'x-access-token': waiterUser?.token,
                }
            })
            if (result.data.status === 200)
                setComandas(result.data.data)
            else
                setComandas([])


            /*
            socket.emit('getComandas', waiterUser?.database, (value) => {
                if (value.status === 200)
                    setComandas(value.data)
                else
                    setComandas([])
            });
            */
        } catch (error) {
            console.log('waiterUser?.database', waiterUser?.database)
        }
    }




    async function comandaClicked(it) {
        try {
            let item = {}
            item = { ...it };
            //  console.log(item)
            switch (item.VendaID) {
                case 0:
                    item.mesaID = item.comandaID
                    item.vendaID = item.VendaID
                    item.chave = item.comandaID
                    item.mesaID = item.comandaID
                    item.tipoAtendimento = 3
                    setMesa(item)


                    let com = {
                        mesaID: item.comandaID,
                        comandaID: item.comandaID,
                        garconID: waiterUser.funid,
                        mesaNome: item.ComandaNome,
                        ComandaNome: item.ComandaNome,
                        tipoAtendimento: 3,
                    }

                    //  return
                    const response = await api.post('api/v1.0/manager/comandas/open', com, {
                        params: {
                            sala: waiterUser?.database
                        },
                        headers: {
                            'x-access-token': waiterUser?.token,
                        }
                    })

                    if (response.data.data.vendaID > 0) {
                        item = JSON.parse(JSON.stringify(item))
                        item.status = 1;
                        item.vendaID = response.data.data.vendaID;
                        item.tipoAtendimento = 3
                        item.mesaID = com.mesaID
                        item.comadaID = com.comandaID
                        item.garconID = waiterUser.funid
                        item.mesaNome = com.ComandaNome
                        item.ComandaNome = com.ComandaNome
                        setMesa(item)
                        navigate('/manager/mesa')
                    } else {
                        notification.error({
                            duration: 4,
                            description: 'Não foi possível efetuar a abertura da comanda!'
                        })
                    }

                    break;

                default:
                    // console.log(item)
                    item.mesaID = item.comandaID
                    item.vendaID = item.VendaID
                    item.chave = item.comandaID
                    item.mesaID = item.comandaID
                    item.tipoAtendimento = 3
                    setMesa(item)
                    navigate('/manager/mesa')
                    break;
            }
        } catch (error) {
            console.error(error.message)
            notification.error({
                duration: 3,
                description: error.message
            })
            // setNotification('Aenção', error.messsage, 'danger')
        }
    }


    return (<>


        <div style={{ margin: 10 }}>
            <Flex wrap gap="small" justify='center'>
                <FlexboxGrid>
                    {comandas?.map((item, i) => {
                        return <FlexboxGrid.Item 
                        style={{ padding: 2 }} 
                        colspan={isMobile ? 8 : 4}
                        key={'COM_'.concat(item.comandaID)}> 
                        <Button
                        block
                            onClick={() => comandaClicked(item)}
                            type='primary'
                            danger={item.VendaID > 0}
                           
                            style={{ height: 45
                            }}>
                            <div style={{ fontSize: '0.8em' }}>
                                {item.ComandaNome} <br />
                                {item.Cliente === 'COM' ? '' : item.Cliente}
                            </div>
                        </Button>
                        </FlexboxGrid.Item>
                    })
                    }
                </FlexboxGrid>
            </Flex>
        </div>
    </>
    )
}
