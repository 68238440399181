import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { waiterUserState, waitDataBaseState, mesaState } from '../../store/dataRecoil'
import { useRecoilState } from 'recoil'
import { useNavigate, useLocation } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Avatar, Button, Drawer, Layout, Flex, Menu, Divider } from 'antd'
import { socket } from '../../service/socket'
import { titleize } from '../../service/util'
import { isMobile } from 'react-device-detect';
import './waiterOutlet.css'
import orderPNG from '../../img/checklist.png'
import clientesPNG from '../../img/team.png'
import productsPNG from '../../img/products.png'
import financePNG from '../../img/finance.png'
import logoutPNG from '../../img/check-out.png'
import menPNG from '../../img/man.png'
import womanPNG from '../../img/woman.png'


export const WaiterOutlet = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const [waiterUser, setWaiterUser] = useRecoilState(waiterUserState)
    const [open, setOpen] = useState(false)
    const [waitDataBase, setWaitDataBase] = useRecoilState(waitDataBaseState)
    const [mesa, setMesa] = useRecoilState(mesaState)

    const items = [

        getItem(<span style={{ fontWeight: 500 }}>Controle de Pedidos </span>, 'sub0', <img src={orderPNG} height={20} />, [
            getItem('Mesas', '/manager'),
            getItem('Comandas', '/manager/comandas'),
            getItem('Monitor', '/manager/monitor')
        ]),
        waiterUser?.cargoid === 1 && getItem(<span style={{ fontWeight: 500 }}>Financeiro</span>, 'sub1', <img src={financePNG} height={20} />,
            [
                getItem('Dashboard', '/manager/dashboad'),
                getItem('Caixas', '/manager/caixas'),
                getItem('Analise Financeira', '/manager/analise'),
                getItem('Contas a pagar', '/manager/contas/pagar'),
                getItem('Vendas movimento', '/manager/vendas/movimentos'),
                getItem('Recebimentos', '/manager/recebimentos'),

            ]),
        waiterUser?.cargoid === 1 && getItem(<span style={{ fontWeight: 500 }}>Produtos</span>, 'sub2', <img src={productsPNG} height={20} />, [
            getItem('Produtos', '/manager/produtos/0'),
            getItem('Insumos', '/manager/produtos/1'),
            getItem('Estoque crítico', '/manager/produtos/critico'),
            getItem('Movimentos', '/manager/movimentos/produtos')
        ]),
        waiterUser?.cargoid === 1 && getItem(<span style={{ fontWeight: 500 }}>Clientes</span>, 'sub3', <img src={clientesPNG} height={20} />, [
            getItem('Melhores clientes', '/manager/clientes/melhores'),
            getItem("Entregas bairros", '/manager/bairros/entregas')
        ]),
        {
            type: 'divider',
        },
        getItem(<span style={{ fontWeight: 500 }}>Outros</span>,
            'sair', null,
            [
                getItem("QRCodes mesas", '/manager/qrcodes'),
                getItem('Sair', 'sair', <img src={logoutPNG} height={20} />)], 'group'),
    ];



    useEffect(() => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = 'https://menuengfood.com.br/logos/logomanager.png';
        document.title = "Manager Online"

    }, []);



    useEffect(() => {
        //  console.log(waiterUser)
        if (!waiterUser) {
            navigate('/manager/login')
            //setWaitDataBase(undefined)
        } else if (!waiterUser.id) {
            setWaitDataBase(undefined)
            setWaiterUser(undefined)
            //  navigate('/manager/login')
        }




    }, [waiterUser])

    function getItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }

    const onClick = (e) => {
        if (e.key === 'sair') {
            setWaiterUser(undefined)
            navigate('/manager/login')
        } else {
            navigate(e.key)
        }
        setOpen(false)
    };


    useEffect(() => {
        function onConnect() {
            socket.emit('sala', waiterUser?.database?.concat('-waiter'))
        }

        function onDisconnect() {
        }

        function onMesasEvent(value) {
            console.log("onMesasEvent: ", value)
        }

        function onMesas(value) {
            //   console.log("onMesasEvent: ", value)
        }

        socket.on(waiterUser?.database, onMesasEvent);
        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('mesas', onMesas);

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off(waiterUser?.database, onMesasEvent);
            socket.off('mesas', onMesas);

        };
    }, []);


    function renderUser() {
        return <Flex align='center'>
            <img height={32} src={waiterUser?.sex === 'F' ? womanPNG : menPNG} style={{ marginRight: 5 }} />
            <div vertical gap={'small'}>
                <div>{titleize(waiterUser?.cargo ? waiterUser?.cargo : '')}</div>
                <div style={{
                    fontSize: '0.8em',
                    color: 'orange',
                    fontStyle: 'italic'
                }}>{titleize(waiterUser?.nome ? waiterUser?.nome : '')}</div>
            </div>
        </Flex>
    }



    return <Layout style={{
        height: '100vh',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column'
    }}>
        <Layout.Header style={{
            position: 'sticky', top: 0,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            backgroundColor: location.pathname === '/manager/monitor' ? 'orangered' : 'navy',
        }}
            className='plano-de-fundo-header'>

            <Flex align='center' style={{ padding: 0, margin: 0 }}
                onClick={() => setOpen(true)}>
                <Avatar src={require('../../img/logo.png')} />
                <div style={{
                    marginLeft: 10,
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    color: 'white'
                }}>{waiterUser?.unidade} </div>
                
            </Flex>

        </Layout.Header>
        <Layout.Content id='meu' style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column'
        }}>
            {isMobile ? <Outlet />
                :
                <Layout >
                    <Layout.Sider

                        theme='light'
                        width={250}
                        style={{
                            backgroundColor: 'whitesmoke',
                            borderRightStyle: 'double',
                            borderRightWidth: 1,
                            borderRightColor: 'lightgray',
                            overflowY: 'auto'
                        }}>
                        <Flex vertical style={{
                            backgroundColor: 'white'
                        }}>
                            <div>
                                {!isMobile &&
                                    renderUser()
                                }
                                <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                            </div>
                            <Menu
                                onClick={onClick}
                                defaultOpenKeys={
                                    waiterUser?.cargoid === 1 ?
                                        ['sub1'] :
                                        ["sub0"]}
                                mode="inline"
                                items={items}
                            />
                        </Flex>
                    </Layout.Sider>
                    <Layout.Content id='meu' style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        overflowY: 'auto'
                    }}>
                        <Outlet />
                    </Layout.Content>
                </Layout>
            }
        </Layout.Content>

        {isMobile && <Drawer
            closable={false}
            zIndex={99999}
            open={open}
            onClose={() => { setOpen(false) }}
            placement='left'
            title={<Flex align='center'>
                <Button type='text'
                    style={{ marginRight: 10 }}
                    icon={<ArrowLeftOutlined style={{ color: 'black', marginRight: 10 }} />} onClick={() => setOpen(false)} />
                <Flex vertical gap={'smal'}>
                    {renderUser()}

                </Flex>
            </Flex>
            } >
            <Menu
                onClick={onClick}
                defaultOpenKeys={
                    waiterUser?.cargoid === 1 ?
                        ['sub1', 'sub2', 'sub3'] :
                        ["sub0"]}
                mode="inline"
                items={items}
            />
        </Drawer>
        }


    </Layout>





    return (<div className='container-outlet'>
        <header
            className='plano-de-fundo-header'
            style={{
                cursor: 'pointer',
                backgroundColor: location.pathname === '/manager/monitor' ? 'orangered' : 'royalblue',
                height: 50
            }}
            onClick={() => setOpen(true)}>
            <div style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0,
                color: 'white', justifyContent: 'space-between'
            }}>
                <div style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10,
                    color: 'white', justifyContent: 'space-between',
                }}>
                    <Avatar src={require('../../img/logo.png')} />
                    <div style={{ marginLeft: 10, textTransform: 'uppercase', fontWeight: 'bold' }}>{waiterUser?.unidade}</div>
                </div>
            </div>
        </header>

        {
            isMobile ? <main style={{ paddingTop: 53, backgroundColor: 'green' }}> <Outlet /> </main> : <main>
                <Layout >
                    <Layout.Sider theme='light'
                        width={230}
                        style={{
                            backgroundColor: 'whitesmoke',
                            borderRightStyle: 'double',
                            borderRightWidth: 1,
                            borderRightColor: 'lightgray'

                        }}>
                        <Flex vertical style={{ backgroundColor: 'white' }}>
                            <div>
                                {!isMobile &&
                                    renderUser()
                                }
                                <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                            </div>
                            <Menu
                                onClick={onClick}
                                defaultOpenKeys={
                                    waiterUser?.cargoid === 1 ?
                                        ['sub1'] :
                                        ["sub0"]}
                                mode="inline"
                                items={items}
                            />
                        </Flex>
                    </Layout.Sider>
                    <Layout.Content>
                        <Outlet />
                    </Layout.Content>
                </Layout>
            </main>
        }



        {isMobile && <Drawer
            closable={false}
            open={open}
            onClose={() => { setOpen(false) }}
            placement='left'
            title={<Flex align='center'>
                <Button type='text'
                    style={{ marginRight: 10 }}
                    icon={<ArrowLeftOutlined style={{ color: 'white', marginRight: 10 }} />} onClick={() => setOpen(false)} />
                <Flex vertical gap={'smal'}>
                    {renderUser()}

                </Flex>
            </Flex>
            }



        >
            <Menu
                onClick={onClick}
                defaultOpenKeys={
                    waiterUser?.cargoid === 1 ?
                        ['sub1', 'sub2', 'sub3'] :
                        ["sub0"]}
                mode="inline"
                items={items}
            />
        </Drawer>
        }

    </div>
    )
}

